import HighlightedPagesSection from '@organisms/HighlightedPagesSection/HighlightedPagesSection';
import Sections from '@organisms/Sections/Sections';
import useStore from '@utils/useStore';
import PropTypes from 'prop-types';

import HomeHero from './HomeHero';

function Home({ page }) {
  const {
    store: {
      config: { quickMenuItems },
    },
  } = useStore();

  return (
    <>
      <HomeHero
        title={page.title}
        subtitle={page.subtitle}
        introText={page.introText}
        image={page.image}
        quickMenuItems={quickMenuItems}
      />
      <HighlightedPagesSection highlightedPages={page.highlightedPages} />
      <Sections sections={page.sections} />
    </>
  );
}

Home.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    introText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string,
      placeholder: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    quickMenuItems: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.shape({
          icon: PropTypes.string,
        }),
        pages: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string,
          })
        ),
      })
    ),
    highlightedPages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          placeholder: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }).isRequired,
        slug: PropTypes.string,
      })
    ),
    sections: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, typename: PropTypes.string })
    ),
  }),
};

export default Home;
